import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import "./OceanPollution.css";
import DebrisPopup from "./DebrisPopup";
import PestPopup from "./PestPopup";
import QuizPopup from "./QuizPopup";

// had to hardcode simple data cuz no one provide
// debris image path and description
const debrisList = [
  {
    id: 1,
    name: "Plastic bottles",
    icon: "/debris/plastic_bottle_icon.png",
    photo: "/debris/plastic_bottle.png",
    damage:
      "Plastic bottles take hundreds of years to degrade and can be mistaken for food by marine animals.",
    weight: "2236",
  },
  {
    id: 2,
    name: "Fishing nets",
    icon: "/debris/fishing_net_icon.png",
    photo: "/debris/fishing_net.png",
    damage:
      'Fishing nets can trap and kill marine life, often referred to as "ghost nets".',
    weight: "122",
  },
  {
    id: 3,
    name: "Food packaging",
    icon: "/debris/food_packaging_icon.png",
    photo: "/debris/food_packaging.png",
    damage:
      "Food packaging pollutes the ocean and harms animals that ingest it.",
    weight: "9415",
  },
  {
    id: 4,
    name: "Crates",
    icon: "/debris/crate_icon.png",
    photo: "/debris/crate.png",
    damage:
      "Crates can break down into smaller plastic pieces that are harmful to marine life.",
    weight: "20",
  },
  {
    id: 5,
    name: "Cigarette butts",
    icon: "/debris/cigarette_butt_icon.png",
    photo: "/debris/cigarette_butt.png",
    damage:
      "Cigarette butts are toxic to marine life and are the most common type of ocean trash.",
    weight: "6889",
  },
  {
    id: 6,
    name: "Gloves",
    icon: "/debris/glove_icon.png",
    photo: "/debris/glove.png",
    damage:
      "Gloves can entangle marine animals and also contribute to ocean microplastics.",
    weight: "961",
  },
  {
    id: 7,
    name: "Buckets",
    icon: "/debris/bucket_icon.png",
    photo: "/debris/bucket.png",
    damage: "Buckets can break down into microplastics and pollute the ocean.",
    weight: "38",
  },
  {
    id: 8,
    name: "Rope",
    icon: "/debris/rope_icon.png",
    photo: "/debris/rope.png",
    damage: "Rope can entangle marine animals, leading to injury or death.",
    weight: "151",
  },
  {
    id: 9,
    name: "Fishing gear",
    icon: "/debris/fishing_gear_icon.png",
    photo: "/debris/fishing_gear.png",
    damage:
      'Lost fishing gear continues to trap marine life, a phenomenon known as "ghost fishing".',
    weight: "2379",
  },
  {
    id: 10,
    name: "Packing materials",
    icon: "/debris/packing_materials_icon.png",
    photo: "/debris/packing_materials.png",
    damage:
      "Packing materials can suffocate marine life and pollute the ocean.",
    weight: "17677",
  },
  {
    id: 11,
    name: "Light globes",
    icon: "/debris/light_globe_icon.png",
    photo: "/debris/light_globe.png",
    damage:
      "Light globes can break and release toxic materials into the ocean.",
    weight: "30",
  },
  {
    id: 12,
    name: "Plastic bags",
    icon: "/debris/plastic_bag_icon.png",
    photo: "/debris/plastic_bag.png",
    damage:
      "Plastic bags are mistaken for food by marine animals, especially turtles.",
    weight: "1270",
  },
  {
    id: 13,
    name: "Plastic microbeads",
    icon: "/debris/plastic_microbeads_icon.png",
    photo: "/debris/plastic_microbeads.png",
    damage:
      "Plastic microbeads are tiny and easily ingested by marine life, entering the food chain.",
    weight: "24146",
  },
];

// pest image path and description
const pestList = [
  {
    id: 1,
    name: "Aquarium caulerpa",
    icon: "/pest/aquarium_caulerpa_icon.png",
    photo: "/pest/aquarium_caulerpa.png",
    effect:
      "It grows everywhere and makes it hard for fish to live. It can also get tangled in fishing gear.",
    feature: "Light green, with flat leaves that stick up and grow in pairs.",
    whereToLook: "On mud, rocks, sand, and seagrass beds.",
    position: { top: "60%", left: "32%" },
    count: "2109",
  },
  {
    id: 2,
    name: "Asian date or bag mussel",
    icon: "/pest/asian_date_bag_mussel_icon.png",
    photo: "/pest/asian_date_bag_mussel.png",
    effect: "Grows very fast and can cover up other sea life.",
    feature: "Brown or green with zigzag lines on the shell.",
    whereToLook: "On man-made stuff, mud, rocks, and sand.",
    position: { top: "35%", left: "10%" },
    count: "94",
  },
  {
    id: 3,
    name: "Asian shore crab",
    icon: "/pest/asian_shore_crab_icon.png",
    photo: "/pest/asian_shore_crab.png",
    effect: "It takes food and space from other crabs and shellfish.",
    feature:
      "A square shell, green to orange with red spots on claws, and no hairs on legs.",
    whereToLook: "Under rocks, shells, and mud.",
    position: { top: "65%", left: "65%" },
    count: "286",
  },
  {
    id: 4,
    name: "European fan worm",
    icon: "/pest/european_fan_worm_icon.png",
    photo: "/pest/european_fan_worm.png",
    effect:
      "Makes lots of colonies and eats a lot of food, which can be bad for other sea creatures.",
    feature: "A spiraled fan that can be white, yellow, orange, or red.",
    whereToLook: "On man-made things, mud, rocks, and sand.",
    position: { top: "35%", left: "65%" },
    count: "396",
  },
  {
    id: 5,
    name: "European shore crab",
    icon: "/pest/european_shore_crab_icon.png",
    photo: "/pest/european_shore_crab.png",
    effect:
      "It’s a tough crab that takes food and space from other sea animals.",
    feature:
      "Green or brown shell with a pale orange underside, and spiky sides near the eyes.",
    whereToLook: "In mud or sand burrows, mangroves, and rocky areas.",
    position: { top: "60%", left: "90%" },
    count: "562",
  },
  {
    id: 6,
    name: "New Zealand screw shell",
    icon: "/pest/new_zealand_screw_shell_icon.png",
    photo: "/pest/new_zealand_screw_shell.png",
    effect:
      "Covers the sea floor with many shells and competes with other shellfish.",
    feature: "Brown shell that turns purple or white as it gets older.",
    whereToLook: "In crevices, mud, rocks, and sand.",
    position: { top: "25%", left: "30%" },
    count: "359",
  },
  {
    id: 7,
    name: "Northern Pacific sea-star",
    icon: "/pest/northern_pacific_sea_star_icon.png",
    photo: "/pest/northern_pacific_sea_star.png",
    effect: "A big sea star that eats oysters, mussels, and scallops.",
    feature: "Yellow to orange with purple spots and 5 arms with pointed tips.",
    whereToLook: "On man-made stuff, mud, rock pools, and rocky areas.",
    position: { top: "70%", left: "10%" },
    count: "725",
  },
];

// pollution image path and description
const pollutionTypes = {
  chemical: {
    title: "Chemical Pollution",
    description:
      "This is when harmful chemicals from farms and factories get into the ocean.",
    imageUrl: "/pollution/chemical_pollution.png",
    details: [
      {
        name: "Agricultural Runoff (Nutrient Pollution)",
        details:
          "Chemicals used for farming wash away from farms into oceans, providing nutrients for algae to bloom. This takes away oxygen from underwater animals.",
        imageUrl: "/pollution/agricultural_runoff.png",
      },
      {
        name: "Industrial Waste",
        details:
          "Dangerous chemicals and heavy metals from factories get into the ocean as a waste, making it a toxic place for marine life.",
        imageUrl: "/pollution/industrial_waste.png",
      },
    ],
  },
  oil: {
    title: "Oil Pollution",
    description:
      "This happens when oil spills from ships or pipes and covers the ocean.",
    imageUrl: "/pollution/oil_pollution.png",
    details: [
      {
        name: "Oil Spills",
        details:
          "When ships or oil platforms have accidents, they can spill oil that covers and harms birds, fish, and their habitats.",
        imageUrl: "/pollution/oil_spills.png",
      },
      {
        name: "Urban Runoff",
        details:
          "Oil and other harmful materials from cities wash down the drains during rain time and go into the ocean.",
        imageUrl: "/pollution/urban_runoff.png",
      },
    ],
  },
  sewage: {
    title: "Wastewater Pollution",
    description:
      "Dirty water from our homes and cities can end up in the ocean, making it dirty and harmful.",
    imageUrl: "/pollution/sewage_pollution.png",
    details: [
      {
        name: "Untreated Sewage",
        details:
          "Sometimes, sewage water isn't cleaned properly before it's dumped into the sea, bringing germs and pollution.",
        imageUrl: "/pollution/untreated_sewage.png",
      },
      {
        name: "Stormwater Runoff",
        details:
          "Water from storms can pick up all sorts of trash and pollutants from streets and carry them into the sea.",
        imageUrl: "/pollution/stormwater_runoff.png",
      },
    ],
  },
};

const quizData = {
  topic: "Ocean Pollution",
  level: "General Knowledge",
  totalQuestions: 5,
  perQuestionScore: 10,
  questions: [
    {
      question:
        "According to the video, for how many years have the families of Green Turtles been roaming in the world's ocean?",
      choices: [
        "10 million years",
        "50 million years",
        "100 million years",
        "1 million years",
      ],
      correctAnswer: "100 million years",
      explanation:
        "Green Turtles have been part of the ocean's ecosystem for about 100 million years, witnessing many changes.",
      image: "/animalquiz/9.png",
    },
    {
      question:
        "According to Mr. Green Turtle in the video, pollution is damaging what in their home?",
      choices: ["Coral Reefs", "SeaGrass Bed", "Sandy Beaches", "Fish Schools"],
      correctAnswer: "SeaGrass Bed",
      explanation:
        "Pollution is particularly harmful to SeaGrass Beds, which are crucial for the ecosystem and provide food and shelter to many marine species.",
      image: "/animalquiz/10.png",
    },
    {
      question: "What is water pollution?",
      choices: [
        "Trash and chemicals in the water",
        "Water turning into ice",
        "Fish swimming in the water",
        "Water becoming clean",
      ],
      correctAnswer: "Trash and chemicals in the water",
      explanation:
        "Water pollution consists of trash and harmful chemicals that disrupt the aquatic environment and pose threats to marine life.",
    },
    {
      question: "Which animal is considered an ocean pest?",
      choices: ["Whale", "Dolphin", "Sea Turtle", "European Shore Crab"],
      correctAnswer: "European Shore Crab",
      explanation:
        "The European Shore Crab is often considered an ocean pest due to its invasive nature, disrupting local ecosystems.",
    },
    {
      question: "What is ocean debris?",
      choices: [
        "Trash in the ocean",
        "Fish swimming",
        "Clean water",
        "Seaweed floating",
      ],
      correctAnswer: "Trash in the ocean",
      explanation:
        "Ocean debris refers to the trash that accumulates in the ocean, creating hazardous conditions for marine life.",
    },
  ],
};

const OceanPollution = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [currentDebris, setCurrentDebris] = useState(null);
  const [activeDebris, setActiveDebris] = useState(debrisList);
  const [activePests, setActivePests] = useState(pestList);
  const [debrisMessage, setDebrisMessage] = useState("");
  const [pestMessage, setPestMessage] = useState("");
  const [activePollution, setActivePollution] = useState("");
  const [animationClass, setAnimationClass] = useState("");
  const [isQuizOpen, setIsQuizOpen] = useState(false);
  const [showSummary, setShowSummary] = useState(false);

  const handleSummaryClick = () => {
    setShowSummary((prevState) => !prevState); // Toggle summary visibility
  };

  const onDragStartDebris = (e, debris) => {
    setCurrentDebris(debris);
  };

  // after debris is dragged away, remove from array
  const onDropDebris = (e) => {
    e.preventDefault();
    if (currentDebris) {
      setDebrisMessage(currentDebris);
      setActiveDebris((prev) =>
        prev.filter((item) => item.id !== currentDebris.id)
      );
    }
  };

  // useEffect(() => {
  //   console.log("Active pests:", activePests);
  // }, [activePests]);

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const closeDebrisPopup = () => {
    setDebrisMessage("");
  };

  // reset game by reset the array
  const resetGameDebris = () => {
    setActiveDebris(debrisList);
    setDebrisMessage("");
  };

  // Start dragging the net
  const onDragStartNet = (e) => {
    e.dataTransfer.setData("draggingNet", "true");
  };

  // remove pest from array once caught
  const onDropPest = (e, pest) => {
    e.preventDefault();
    const draggingNet = e.dataTransfer.getData("draggingNet");
    if (draggingNet === "true") {
      setPestMessage(pest);
      setActivePests((prev) => prev.filter((item) => item.id !== pest.id));
    }
  };

  const onDragOverPest = (e) => {
    e.preventDefault();
  };

  const closePestPopup = () => {
    setPestMessage("");
  };

  // reset game by reset the array
  const resetGamePest = () => {
    setActivePests(...[pestList]);
    setPestMessage("");
  };

  // handle content rendering when clicking on pollution type
  const handleClick = (type) => {
    console.log("Type clicked:", type);
    console.log("Pollution data for type:", pollutionTypes[type]);
    setActivePollution(pollutionTypes[type]);
  };

  const goToRoute = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (activePollution) {
      setAnimationClass("pollution-detail-active"); // Trigger flip in
    } else {
      setAnimationClass("pollution-detail-inactive"); // Trigger flip out
      // Clear the animation class after the animation duration
      const timer = setTimeout(() => setAnimationClass(""), 600);
      return () => clearTimeout(timer); // Clear timeout if the component unmounts
    }
  }, [activePollution]);

  return (
    <main>
      <Header />
      <div id="marine-container">
        <div id="video-container">
          <video controls id="intro-video" ref={videoRef} autoPlay>
            <source src="/videos/Green Turtle.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {/* Button on top of video */}
          <button className="video-summary-button" onClick={handleSummaryClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              className="bi bi-question-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247m2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
            </svg>
            <span className="tooltip-video">Video Summary</span>
          </button>
          {showSummary && (
            <div>
              <p>
                This video, featuring a Green Turtle, explains how
                pollution, such as plastic waste and discarded fishing gear,
                threatens its home in the seagrass beds of the Great Barrier
                Reef. The video encourages children to help protect the ocean by
                learning about different types of pollution and how they can
                recognize and avoid them in their daily lives. This sets the
                stage for further exploration into ocean waste and pollution,
                empowering young viewers to take part in conservation efforts.
              </p>
            </div>
          )}
        </div>

        {/* Pollution Intro  */}
        <div id="intro-container">
          <h2 className="section-heading">
            Different Types of Ocean Pollution
          </h2>
          <p className="section-text">
            On this page, you'll see pictures and stories of different types of
            ocean pollution. Now, Let's get going!
          </p>
        </div>

        {/* ocean cleanup game  */}
        <div id="section-container">
          <div className="text-container">
            <h3 className="section-heading">Ocean Debris</h3>
            <p className="section-text">
              Ocean debris are trash that end up in the ocean. Animals can get
              hurt or sick if they eat or get tangled in them.
            </p>
            <p className="section-text">
              Now, let's clean up all debris from this ocean!
            </p>
            <div className="arrow-container">
              <img src="/debris/arrow.png" alt="arrow" className="arrow" />
            </div>
          </div>
          <div id="op-background-container">
            <video id="op-background-video" autoPlay loop muted>
              <source src="/debris/sea.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="game-container">
              <div className="gif-wrapper">
                <img
                  src="/debris/click.gif"
                  alt="click"
                  style={{ width: "300px", height: "auto" }}
                />
              </div>
              <div
                className="bin-area"
                onDrop={onDropDebris}
                onDragOver={onDragOver}
              >
                <img src="/debris/bin.png" alt="Bin" className="bin" />
              </div>
              <div className="debris-area">
                {activeDebris.map((debris) => (
                  <div
                    key={debris.id}
                    className="debris-item"
                    draggable
                    onDragStart={(e) => onDragStartDebris(e, debris)}
                  >
                    <img src={debris.icon} alt={debris.name} />
                  </div>
                ))}
              </div>
              {activeDebris.length === 0 && (
                <div className="congrats-message">
                  <img src="/debris/metal.png" alt="metal" className="metal" />
                  <h2>Congratulations!</h2>
                  <p>You've cleaned up all the ocean debris!</p>
                </div>
              )}
              {debrisMessage && (
                <DebrisPopup
                  message={debrisMessage}
                  closePopup={closeDebrisPopup}
                />
              )}
            </div>
            <button className="reset-button" onClick={resetGameDebris}>
              <img src="/debris/reset.png" alt="Reset Bin" />
            </button>
          </div>
        </div>

        {/* Water Pollution info */}
        <div id="section-container">
          <div className="container mt-2">
            {activePollution ? (
              <div className={`pollution-detail ${animationClass}`}>
                <h2>{activePollution.title}</h2>
                <p>{activePollution.description}</p>
                <div className="d-flex justify-content-around flex-wrap">
                  {activePollution.details.map((detail, index) => (
                    <div key={index} className="flex-item text-center">
                      <div className="subtype-detail">
                        <img
                          src={detail.imageUrl}
                          alt={detail.name}
                          className="img-fluid rounded-circle"
                        />
                        <h5>{detail.name}</h5>
                        <p>{detail.details}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <button
                  onClick={() => setActivePollution(null)}
                  type="button"
                  className="btn btn-outline-light"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    fill="currentColor"
                    className="bi bi-arrow-left-circle-fill"
                    viewBox="0 0 18 16"
                  >
                    <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"></path>
                  </svg>
                  Back
                </button>
              </div>
            ) : (
              <div className="row">
                <h3 className="section-heading">Water Pollution</h3>
                <p className="section-text">
                  Water pollution is when harmful things like trash, chemicals,
                  or waste get into oceans. This can hurt the ocean animals and
                  even make it dangerous for us to swim in it.
                </p>
                {Object.entries(pollutionTypes).map(([type, data]) => (
                  <div key={type} className="col-md-4 text-center mb-4">
                    <img
                      src={data.imageUrl}
                      alt={data.title}
                      className="pollution-icon"
                      style={{ cursor: "pointer", marginBottom: "10px" }}
                      onClick={() => handleClick(type)}
                    />
                    <button
                      type="button"
                      className="btn btn-outline-light subsection"
                      onClick={() => handleClick(type)}
                    >
                      {data.title}
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Pest game */}
        <div id="section-container">
          <div className="text-container">
            <h3 className="section-heading">Ocean Pests</h3>
            <p className="section-text">
              Ocean pests are animals that don't belong in a certain part of the
              ocean. They can be harmful when they eat too much food or take
              over the homes of other animals.
            </p>
            <p className="section-text">Now, help catch some ocean pests!</p>
            <div className="arrow-container">
              <img src="/pest/arrow.png" alt="arrow" className="arrow" />
            </div>
          </div>
          <div id="op-background-container">
            <video id="op-background-video" autoPlay loop muted>
              <source src="/pest/sea.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="pest-game-container">
              <div className="gif-wrapper">
                <img
                  src="/pest/click.gif"
                  alt="click"
                  style={{ width: "300px", height: "auto" }}
                />
              </div>
              <div className="net-area" draggable onDragStart={onDragStartNet}>
                <img src="/pest/net.png" alt="Fishing Net" className="net" />
              </div>

              <div className="pest-area">
                {activePests.map((pest) => (
                  <div
                    key={pest.id}
                    className="pest-item"
                    onDrop={(e) => onDropPest(e, pest)}
                    onDragOver={onDragOverPest}
                    style={{ top: pest.position.top, left: pest.position.left }}
                  >
                    <img src={pest.icon} alt={pest.name} />
                  </div>
                ))}
              </div>

              {activePests.length === 0 && (
                <div className="congrats-message">
                  <img src="/debris/metal.png" alt="metal" className="metal" />
                  <h2>Congratulations!</h2>
                  <p>You've cleaned up all the ocean pests!</p>
                </div>
              )}

              {pestMessage && (
                <PestPopup message={pestMessage} closePopup={closePestPopup} />
              )}
            </div>
            <button onClick={resetGamePest} className="reset-button">
              <img src="/debris/reset.png" alt="Reset Game" />
            </button>
          </div>
        </div>

        {/* summary and quiz */}
        <div id="intro-container">
          <h4 className="section-heading">
            Wow! Now you know so much about what harm our ocean!
          </h4>
          <h4 className="section-heading">
            But what can YOU do to help protect our beautiful ocean and
            creatures?
          </h4>
          <p className="section-text">Now, you can:</p>
          <div className="row option">
            <div className="col-4 sub-option">
              <p className="section-text">
                Take a quiz and test what you've learnt about ocean pollution.
              </p>
              <button
                type="button"
                className="btn btn-outline-light"
                onClick={() => setIsQuizOpen(true)}
              >
                Start Quiz
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  fill="currentColor"
                  className="bi bi-arrow-right-circle-fill ms-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                </svg>
              </button>
              <QuizPopup
                isOpen={isQuizOpen}
                onClose={() => setIsQuizOpen(false)}
                quizData={quizData}
              />
            </div>
            <div className="col-1 mt-4">
              <h3>OR</h3>
            </div>
            <div className="col-4 sub-option">
              <p className="section-text">
                Continue your journey to learn about ways to protect our ocean!
              </p>
              <button
                type="button"
                className="btn btn-outline-light"
                onClick={() => goToRoute("/ocean-conservation")}
              >
                Next
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  fill="currentColor"
                  className="bi bi-arrow-right-circle-fill ms-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default OceanPollution;
