import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import EndangeredSpecies from "./components/EndangeredSpecies";
import Footer from "./components/Footer";
import Home from "./components/Home";
import MarineThreats from "./components/MarineThreats";
import OceanPollution from "./components/OceanPollution";
import OceanConservation from "./components/OceanConservation";
import Password from "./components/password";
import Map from "./components/Map";
import Media from "./components/Media";

function App() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Router>
      <Password correctPassword={"awesometp30"}>
        <div className="d-flex flex-column min-vh-100">
          <div className="flex-grow-1">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/endangered-species" element={<EndangeredSpecies />} />
              <Route path="/ocean-pollution" element={<OceanPollution />} />
              <Route path="/marine-threats" element={<MarineThreats />} />
              <Route path="/ocean-conservation" element={<OceanConservation />} />
              <Route path="/australia" element={<Map />} />
              <Route path="/media" element={<Media />} />
            </Routes>
          </div>
          <Footer />
        </div>
        {showButton && (
  <button className="back-to-top visible" onClick={scrollToTop}>
    ↑ Back to Top
  </button>
)}
      </Password>
    </Router>
  );
}

export default App;
