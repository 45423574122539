import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "./QuizPopup.css"; // Make sure to adjust this for Bootstrap styling

const QuizPopup = ({ isOpen, onClose, quizData }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [showExplanation, setShowExplanation] = useState(false);
  const [quizFinished, setQuizFinished] = useState(false);

  if (!isOpen) return null;

  const currentQuestion = quizData.questions[currentQuestionIndex];

  const handleAnswerChange = (option) => {
    setSelectedAnswer(option);
    setShowExplanation(true);
  };

  const handleNext = () => {
    setShowExplanation(false);
    setSelectedAnswer(null);
    if (currentQuestionIndex < quizData.totalQuestions - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    } else {
      setQuizFinished(true);
    }
  };

  const handlePrevious = () => {
    setShowExplanation(false);
    setSelectedAnswer(null);
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setSelectedAnswer(null);
    setShowExplanation(false);
    setQuizFinished(false);
  };

  const isCorrect = selectedAnswer === currentQuestion.correctAnswer;

  return (
    <Modal show={isOpen} onHide={onClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{quizData.topic} Quiz</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {quizFinished ? (
          <div className="text-center">
            <img
              src="/pest/metal.png"
              alt="congraduations"
              style={{ width: "50%" }}
            />
            <h3>Congratulations!</h3>
            <p>You've completed this quiz. Well done!</p>
            <Button
              type="button"
              className="btn btn-outline-light quiz"
              onClick={handleRetake}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-clockwise"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
                />
                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
              </svg>
              Retake Quiz
            </Button>
            <Button
              type="button"
              className="btn btn-outline-light quiz"
              onClick={onClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
              </svg>
              Close Quiz
            </Button>
          </div>
        ) : (
          <>
            <h5 style={{ marginBottom: "10px" }}>{currentQuestion.question}</h5>
            <div className="row">
              {currentQuestion.image ? (
                <>
                  <div className="col-md-6 col-12 d-flex align-items-center">
                    <img
                      className="quiz-image"
                      src={currentQuestion.image}
                      alt={currentQuestion.question}
                      style={{ width: "100%", height: "auto" }} // Ensure the image fits within its container
                    />
                  </div>
                  <div className="col-md-6 col-12 d-flex flex-column align-items-center">
                    <div className="d-grid gap-2 col-12 mx-auto">
                      {currentQuestion.choices.map((choice, index) => (
                        <Button
                          key={index}
                          variant={
                            selectedAnswer === choice
                              ? isCorrect
                                ? "success"
                                : "danger"
                              : "secondary"
                          }
                          onClick={() => handleAnswerChange(choice)}
                          style={{ marginTop: "10px" }}
                        >
                          {choice}
                        </Button>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-12 d-flex flex-column align-items-center">
                  <div className="d-grid gap-2 col-12 mx-auto">
                    {currentQuestion.choices.map((choice, index) => (
                      <Button
                        key={index}
                        variant={
                          selectedAnswer === choice
                            ? isCorrect
                              ? "success"
                              : "danger"
                            : "secondary"
                        }
                        onClick={() => handleAnswerChange(choice)}
                        style={{ marginTop: "10px" }}
                      >
                        {choice}
                      </Button>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {showExplanation && (
              <div className="mt-3">
                <p>
                  {isCorrect
                    ? "Congratulations! You got it right!"
                    : `Oops! That’s not correct. The correct answer is: ${currentQuestion.correctAnswer}`}
                </p>
                {currentQuestion.explanation && (
                  <p>{currentQuestion.explanation}</p>
                )}
              </div>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {/* Show "Previous" button only if it's not the first question */}
        {currentQuestionIndex > 0 && !quizFinished && (
          <Button
            onClick={handlePrevious}
            type="button"
            className="btn btn-outline-light quiz"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              fil="currentColor"
              className="bi bi-arrow-left-circle-fill"
              viewBox="0 0 18 16"
            >
              <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"></path>
            </svg>
            Previous
          </Button>
        )}

        {/* Show "Next" or "Finish" button depending on the question index */}
        {!quizFinished && (
          <Button
            type="button"
            className="btn btn-outline-light quiz"
            onClick={handleNext}
          >
            {currentQuestionIndex === quizData.totalQuestions - 1 ? (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill
="currentColor"
                  className="bi bi-check-circle-fill me-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
                Finish
              </>
            ) : (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill
="currentColor"
                  className="bi bi-arrow-right-circle-fill me-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                </svg>
                Next
              </>
            )}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default QuizPopup;
