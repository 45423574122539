import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    // <footer className="text-muted py-1" data-bs-theme="dark">
    //   <div className="container d-flex justify-content-center align-items-center">
    //     <div className="d-inline-flex align-items-center">
    //       <p className="mb-0">@Splash by Seaweed 2024</p>
    //     </div>
    //   </div>
    // </footer>
    <footer
      className="d-flex flex-wrap justify-content-center align-items-center py-3 px-3"
      data-bs-theme="dark"
    >
      <p className="col-md-4 mb-0 mx-5 text-body-secondary">
        <img src="logo/logo_light.png" alt="logo" height="30" className="bi" />@
        2024 Splash.&nbsp;All Rights Reserved{" "}
      </p>
    </footer>

  );
};

export default Footer;
