import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import "./MarineThreats.css";
import QuizPopup from "./QuizPopup";
import Modal from "react-modal";

const quizData = {
  topic: "Ocean Threats",
  level: "General Knowledge",
  totalQuestions: 5,
  perQuestionScore: 10,
  questions: [
    {
      question:
        "How many years has Mr. Humpback Whale been swimming in the ocean?",
      choices: ["70 years", "30 years", "5 years", "80 years"],
      correctAnswer: "80 years",
      explanation:
        "Mr. Humpback Whale has been navigating the ocean waters for 80 years, experiencing many changes during this time.",
    },
    {
      question:
        "Mr. Humpback Whale says which of his friends are starting to disappear?",
      choices: [
        "Tuna and Swordfish",
        "Dolphins and Sharks",
        "Jellyfish and Starfish",
        "Seals and Penguins",
      ],
      correctAnswer: "Tuna and Swordfish",
      explanation:
        "Tuna and Swordfish populations are diminishing, partly due to overfishing and changes in their habitats.",
    },
    {
      question:
        "Mr. Humpback Whale says strange things are starting to fill the oceans. What are they?",
      choices: [
        "Plastic Bottles, Bags, and Oils",
        "Fish, Seaweed, and Shells",
        "Boats, Crabs, and Rocks",
        "Sand, Bubbles, and Coral",
      ],
      correctAnswer: "Plastic Bottles, Bags, and Oils",
      explanation:
        "The oceans are increasingly being filled with plastic bottles, bags, and oils, all of which are pollutants affecting marine life.",
    },
    {
      question:
        "What kind of water is making life hard for Mr. Humpback Whale and his friends in their home?",
      choices: ["Cold Water", "Warm Water", "Clean Water", "Rain Water"],
      correctAnswer: "Warm Water",
      explanation:
        "Increasing water temperatures, or warm water, is making life challenging for many marine species, including Mr. Humpback Whale.",
    },
    {
      question:
        "Mr. Humpback Whale says strange things are starting to fill the oceans. What are they?",
      choices: [
        "Plastic Bottles, Bags, and Oils",
        "Fish, Seaweed, and Shells",
        "Boats, Crabs, and Rocks",
        "Sand, Bubbles, and Coral",
      ],
      correctAnswer: "Plastic Bottles, Bags, and Oils",
      explanation:
        "Pollution in the form of plastic bottles, bags, and oils is increasingly contaminating the marine environment.",
    },
  ],
};

const speciesData = [
  {
    name: "Southern Bluefin Tuna",
    image: "/overfishing/1.png",
    reason: "Too many are caught for food, so they are now endangered.",
  },
  {
    name: "Jackass Morwong",
    image: "/overfishing/2.png",
    reason: "People fish for them too much, and it destroys their homes.",
  },
  {
    name: "Upper-Slope Gulper Shark",
    image: "/overfishing/3.png",
    reason:
      "They get caught in nets meant for other fish, which hurts their population.",
  },
  {
    name: "Green Turtle",
    image: "/overfishing/4.png",
    reason:
      "Fishing hurts their homes, and climate change makes it harder for them to survive.",
  },
  {
    name: "Hawksbill Turtle",
    image: "/overfishing/5.png",
    reason:
      "People take their beautiful shells, and their coral homes are being destroyed.",
  },
  // {
  //   name: "Whales",
  //   image: "/overfishing/6.png",
  //   reason: "They were hunted a long time ago, and now it's hard for them to find enough food."
  // },
  {
    name: "Shy Albatross",
    image: "/overfishing/7.png",
    reason:
      "They get caught in fishing lines, and it’s harder for them to find food.",
  },
  {
    name: "Orange Roughy",
    image: "/overfishing/8.png",
    reason:
      "They are overfished because they live a long time but take a long time to grow.",
  },
  {
    name: "Patagonian Toothfish",
    image: "/overfishing/9.png",
    reason:
      "They are fished too much, making it hard for their population to recover.",
  },
];

const MarineThreats = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [oceanHealthData, setOceanHealthData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const [isQuizOpen, setIsQuizOpen] = useState(false);
  const [temperature, setTemperature] = useState(25);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showSummary, setShowSummary] = useState(false);

  const handleSummaryClick = () => {
    setShowSummary((prevState) => !prevState); // Toggle summary visibility
  };

  // nav
  const goToRoute = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  const openModal = () => {
    console.log("Opening modal");
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  // fetching marine health data when page is loaded
  const fetchData = async () => {
    try {
      console.log("Fetching marine threats data...");
      const response = await fetch("https://splash-db.onrender.com/api/health");
      // console.log("Response status:", response.status); // Log the status code
      // console.log(response);
      if (!response.ok)
        throw new Error(
          `Failed to fetch marine threats data: ${response.statusText}`
        );
      const data = await response.json();
      // console.log("Fetched data:", data);
      // sort data by date
      const sortedData = data.sort((a, b) => {
        if (a.year === b.year) {
          return a.month - b.month;
        }
        return a.year - b.year;
      });
      // set use state
      setOceanHealthData(sortedData);
    } catch (error) {
      console.error("Error details:", error);
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleIndexChange = (e) => {
    setCurrentIndex(parseInt(e.target.value));
  };

  // display filled/empty starts for ocean health score
  const getStars = (score) => {
    return (
      <>
        <span className={score >= 1 ? "star filled" : "star empty"}>★</span>
        <span className={score >= 2 ? "star filled" : "star empty"}>★</span>
        <span className={score >= 3 ? "star filled" : "star empty"}>★</span>
      </>
    );
  };

  const currentData = oceanHealthData[currentIndex] || {};

  // ocean health timeline labels
  const getTimelineLabels = () => {
    const totalLabels = 15;
    const labelInterval = Math.floor(oceanHealthData.length / totalLabels);
    return oceanHealthData.filter((_, index) => index % labelInterval === 0);
  };

  // month numeric to text
  const getMonthName = (month) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[month - 1];
  };

  // data to display when click on info
  const handleInfoClick = (type) => {
    let title = "";
    let content = "";
    switch (type) {
      case "oxygen":
        title = "Oxygen";
        content = (
          <>
            <p>
              <strong>What is it?</strong> Oxygen is a special kind of air that
              we need to breathe to stay alive. Fish and other sea animals need
              oxygen in the water to breathe, just like we need it in the air.
            </p>
            <p>
              <strong>Where does it exist?</strong> Oxygen is in the water,
              especially at the top where waves and plants like seaweed help put
              oxygen in the water.
            </p>
            <p>
              <strong>How does it affect ocean health?</strong> If there isn’t
              enough oxygen in the ocean, fish and sea animals can have trouble
              breathing, get sick, or even die. Healthy oceans have lots of
              oxygen for all the sea creatures.
            </p>
          </>
        );
        break;
      case "salinity":
        title = "Salinity";
        content = (
          <>
            <p>
              <strong>What is it?</strong> Salinity is a fancy word for how
              salty the water is. The ocean is salty because rain washes salt
              from land into the sea over a long time.
            </p>
            <p>
              <strong>Where does it exist?</strong> Salinity is in all ocean
              water, but some places are saltier than others. For example, near
              the equator, where it's very hot, more water evaporates, leaving
              extra salt behind.
            </p>
            <p>
              <strong>How does it affect ocean health?</strong> The right amount
              of salt is important for sea animals to stay healthy. If the water
              is too salty or not salty enough, it can be hard for them to live.
            </p>
          </>
        );
        break;
      case "chlorophyll":
        title = "Chlorophyll";
        content = (
          <>
            <p>
              <strong>What is it?</strong> Chlorophyll is a green color found in
              plants, including tiny plants in the ocean called phytoplankton.
              It helps these plants make food from sunlight through a process
              called photosynthesis.
            </p>
            <p>
              <strong>Where does it exist?</strong> Chlorophyll is in all green
              plants, both on land and in the ocean. In the ocean, you can find
              it in the tiny phytoplankton that float near the surface.
            </p>
            <p>
              <strong>How does it affect ocean health?</strong> Chlorophyll is
              important because it helps plants grow, and these plants are food
              for many sea animals. But if there is too much chlorophyll, it can
              use up too much oxygen in the water and make it hard for other sea
              creatures to live.
            </p>
          </>
        );
        break;
      default:
        title = "";
        content = "";
    }
    setPopupContent({ title, content });
    setShowPopup(true);
  };

  // close pop up window
  const closePopup = () => {
    setShowPopup(false);
  };

  // Function to determine the image filter based on temperature
  const getImageStyle = (temp) => {
    const baseTemp = 25;
    const maxTemp = 31;
    const tempRange = maxTemp - baseTemp; // 6 degrees range
    const tempEffect = (temp - baseTemp) / tempRange; // Normalize between 0 and 1

    const brightness = 100 + tempEffect * 40; // Increase brightness up to 150%
    const saturate = 100 - tempEffect * 80; // Decrease saturation down to 0%
    const contrast = 100 - tempEffect * 10; // Slightly reduce contrast

    return {
      filter: `brightness(${brightness}%) saturate(${saturate}%) contrast(${contrast}%)`,
    };
  };

  const getLabelForTemperature = (temperature) => {
    if (temperature < 27) return "Ideal Conditions";
    if (temperature >= 27 && temperature < 29) return "Stress Begins";
    if (temperature >= 29 && temperature < 31) return "Partial Bleaching";
    if (temperature >= 31) return "Severe Bleaching";
  };

  return (
    <main>
      <Header />
      <div id="marine-container">
        {/* intro video  */}
        <div id="video-container">
          <video controls id="intro-video" ref={videoRef} autoPlay>
            <source src="/videos/Humpback Whale.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {/* Button on top of video */}
          <button className="video-summary-button" onClick={handleSummaryClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              className="bi bi-question-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247m2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
            </svg>
            <span className="tooltip-video">Video Summary</span>
          </button>
          {showSummary && (
            <div>
              <p>
                In this video, a story was told by a humpback whale who has been
                swimming in the oceans around Australia for 80 years. Through
                his story, kids learn about the changes in the marine
                environment over time, from the pristine oceans of the early
                20th century to the increasing challenges faced today, such as
                overfishing, plastic pollution, and climate change. Now, the
                whale calls for help, asking children to understand the problems
                affecting marine life as a first step toward protecting it.
              </p>
            </div>
          )}
        </div>

        <div id="intro-container">
          <h4 className="section-heading">
            Mr. Humpback Whale and his friends need your help!
          </h4>
          <p className="section-text">
            Now let't get to know what's causing harm to their home.
          </p>
        </div>

        <div id="section-container">
          <div className="marine-threats">
            <div className="row threat">
              <div className="col-4 align-self-start">
                <h1>
                  <strong>CLIMATE CHANGE</strong>
                </h1>
                <img
                  src="/threats/1.png"
                  alt="Climate Change"
                  className="threats-img"
                />
                <p className="section-text">
                  Climate change is when the Earth is getting warmer because of
                  human activities such as cars, factories, and burning coal to
                  make electricity. When there is too much of these gases, they
                  trap heat from the sun, making the Earth warmer—like when you
                  wear a jacket on a hot day!
                </p>
              </div>
              <div className="col-8">
                <p className="section-text">How does it affects the ocean?</p>
                <div className="row">
                  <div className="col-4">
                    <h4>Ocean Warming</h4>
                    <img
                      src="/threats/1.1.png"
                      alt="Climate Change"
                      className="climate-img"
                    />
                  </div>
                  <div className="col-4">
                    <h4>Rising Sea Level</h4>
                    <img
                      src="/threats/1.2.png"
                      alt="Climate Change"
                      className="climate-img"
                    />
                  </div>
                  <div className="col-4">
                    <h4>Coral Bleaching</h4>
                    <img
                      src="/threats/1.3.png"
                      alt="Climate Change"
                      className="climate-img"
                    />
                  </div>
                </div>
                <div className="coral-temperature-slider">
                  <p className="section-text">
                    Drag the slider to see how coral lose colour as sea
                    temperature rise!
                  </p>
                  <input
                    type="range"
                    min="25"
                    max="31"
                    value={temperature}
                    onChange={(e) => setTemperature(e.target.value)}
                    step="0.1"
                    list="tickmarks"
                    className="coral-slider"
                  />
                  <p>
                    Temperature: {temperature}°C -{" "}
                    {getLabelForTemperature(temperature)}
                  </p>

                  <div>
                    <img
                      src="/threats/coral.png"
                      alt={`Coral at ${temperature}°C`}
                      style={getImageStyle(temperature)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <div className="row threat">
              <div className="col-7">
                <p className="section-text">
                  These are some animals that are impacted by overfishing:
                </p>
                <div className="card-container">
                  {speciesData.map((species) => (
                    <div key={species.name} className="flip-card">
                      <div className="flip-card-inner">
                        <div className="flip-card-front">
                          <img
                            src={species.image}
                            alt={species.name}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </div>
                        <div className="flip-card-back">
                          <h4>{species.name}</h4>
                          <p>{species.reason}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="col-4 align-self-start">
                <h1>
                  <strong>OVERFISHING</strong>
                </h1>
                <img
                  src="/threats/2.png"
                  alt="Overfishing"
                  className="threats-img"
                />
                <p className="section-text">
                  Overfishing is when too many fish are caught, that fish won’t
                  have enough time to grow and have babies, and the ocean can't
                  stay healthy. Some fishing methods also damage their homes,
                  like coral reefs and underwater plants.
                </p>
              </div>
            </div>

            <hr />
            <div className="row threat">
              <div className="col-4 align-self-start">
                <h1>
                  <strong>OCEAN MINING & DRILLING</strong>
                </h1>
                <img
                  src="/threats/3.png"
                  alt="Deep Sea Mining"
                  className="threats-img"
                />
                <p className="section-text">
                  Mining and drilling are ways people access resources from deep
                  under the ocean ground, like oil, gas, and minerals, to help
                  human with our lives. But when people dig too much or don’t do
                  it carefully, it hurts the ocean and the animals that live
                  there.
                </p>
              </div>
              <div className="col-8">
                <p className="section-text">How does it affect the ocean?</p>
                <div className="row drill">
                  <div className="col-3">
                    <img
                      src="/drill/1.png"
                      alt="pollution"
                      className="drill-img"
                    ></img>
                  </div>
                  <div className="col-8 drill-text">
                    <h4>Pollution</h4>
                    <p>
                      Oil spills or dirt from digging can get into the water,
                      making it dangerous for fish, turtles, and other sea
                      animals.
                    </p>
                  </div>
                </div>
                <div className="row drill">
                  <div className="col-3">
                    <img
                      src="/drill/2.png"
                      alt="pollution"
                      className="drill-img"
                    ></img>
                  </div>
                  <div className="col-8 drill-text">
                    <h4>Habitat Disruption</h4>
                    <p>
                      Digging on the ocean floor can ruin the homes of deep-sea
                      creatures that live there.
                    </p>
                  </div>
                </div>
                <div className="row drill">
                  <div className="col-3">
                    <img
                      src="/drill/3.png"
                      alt="pollution"
                      className="drill-img"
                    ></img>
                  </div>
                  <div className="col-8 drill-text">
                    <h4>Too Much Noise</h4>
                    <p>
                      Oil spills or dirt from digging can get into the water,
                      making it dangerous for fish, turtles, and other sea
                      animals.
                    </p>
                  </div>
                </div>
                <p className="section-text mt-5">
                  Did you know? There’s drilling happening right now in a
                  beautiful place called Scott Reef in Western Australia! Many
                  people are calling to save it.
                </p>
                <div className="image-container">
                  <img
                    src="/drill/scott.png"
                    alt="scott reef"
                    className="scott"
                  ></img>

                  <button
                    onClick={openModal}
                    type="button"
                    className="btn-video"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="100"
                      height="100"
                      fill="currentColor"
                      className="bi bi-play-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z" />
                    </svg>
                  </button>
                </div>
                <p className="section-text">
                  Click the play button to learn more about what’s happening,
                  and how these activities cause damage to Scott Reef and its
                  marine residents.
                </p>
              </div>
            </div>
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel="YouTube Modal"
              style={{
                content: {
                  width: "45%",
                  height: "80%",
                  margin: "auto",
                  padding: "0",
                  background: "rgba(255, 255, 255, 0.7)",
                },
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
              }}
            >
              <button
                onClick={closeModal}
                style={{
                  color: "white",
                  position: "absolute",
                  bottom: "7%",
                  right: "3%",
                  backgroundColor: "transparent",
                  border: "none",
                  padding: "10px",
                  borderRadius: "50%",
                  fontSize: "24px",
                  cursor: "pointer",
                  zIndex: 10,
                }}
              >
                ✕
              </button>

              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/-MQAki65wNo"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube video player"
                style={{ border: "none" }}
              ></iframe>
            </Modal>
          </div>
        </div>

        {/* Ocean health visualisation  */}
        <div id="section-container">
          <div className="timeline-container">
            <div className="ocean-health-info intro">
              <h4 className="section-heading">How Healthy Is Our Ocean?</h4>
              <p className="section-text">
                Drag the timeline to see ocean health at different days and
                understand what factors affect its health!
              </p>
              <br />
              <h3 style={{ fontWeight: "bolder" }}>
                {getMonthName(currentData.month)}/{currentData.year}
              </h3>
              <p>
                <strong>Overall Health Score:</strong>{" "}
                {getStars(currentData.all_score)}
              </p>
            </div>
            <div className="slider-container-health">
              <input
                type="range"
                min="0"
                max={oceanHealthData.length - 1}
                value={currentIndex}
                onChange={handleIndexChange}
                className="slider"
              />
              <div className="slider-labels">
                {getTimelineLabels().map(({ year, month }, index) => (
                  <span key={index} className={`slider-label`}>
                    {`${month}/${year}`}
                  </span>
                ))}
              </div>
            </div>

            <div
              className="ocean-health-info d-flex justify-content-between align-items-center"
              style={{ marginTop: "20px" }}
            >
              <div className="health-text">
                <p>
                  Oxygen Level
                  <button
                    type="button"
                    className="btn btn-secondary info-button"
                    onClick={() => handleInfoClick("oxygen")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-question-diamond-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098zM5.495 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.803 0-1.253.478-1.342 1.134-.018.137-.128.25-.266.25zm2.325 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927" />
                    </svg>
                    <span className="tooltip">What is this?</span>
                  </button>
                  {currentData.oxygen?.toFixed(2)} mg/L{" "}
                  {getStars(currentData.oxy)}
                </p>

                <p>
                  Chlorophyll Level
                  <button
                    type="button"
                    className="btn btn-secondary info-button"
                    onClick={() => handleInfoClick("chlorophyll")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-question-diamond-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098zM5.495 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.803 0-1.253.478-1.342 1.134-.018.137-.128.25-.266.25zm2.325 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927" />
                    </svg>
                    <span className="tooltip">What is this?</span>
                  </button>
                  {currentData.chlorophyll?.toFixed(2)} µg/L{" "}
                  {getStars(currentData.cho_score)}
                </p>

                <p>
                  Salinity Level
                  <button
                    type="button"
                    className="btn btn-secondary info-button"
                    onClick={() => handleInfoClick("salinity")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-question-diamond-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098zM5.495 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.803 0-1.253.478-1.342 1.134-.018.137-.128.25-.266.25zm2.325 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927" />
                    </svg>
                    <span className="tooltip">What is this?</span>
                  </button>
                  {currentData.salinity?.toFixed(2)} PSU{" "}
                  {getStars(currentData.sal)}
                </p>
              </div>

              {/* Health score image */}
              <div className="health-image">
                {currentData.all_score === 3 && (
                  <img
                    src="/healthfish/3.png"
                    alt="Happy Fish"
                    className="health-fish"
                  />
                )}
                {currentData.all_score === 2 && (
                  <img
                    src="/healthfish/2.png"
                    alt="Okay Fish"
                    className="health-fish"
                  />
                )}
                {currentData.all_score === 1 && (
                  <img
                    src="/healthfish/1.png"
                    alt="Sad Fish"
                    className="health-fish"
                  />
                )}
              </div>
            </div>

            {showPopup && (
              <div className="popup">
                <div className="popup-content">
                  <h3>{popupContent.title}</h3>
                  <img
                    src={`/health/${popupContent.title}.png`}
                    alt={popupContent.title}
                  />
                  {popupContent.content}
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    onClick={closePopup}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="bi bi-x-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"></path>
                    </svg>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Section Summary */}

        {/* summary and quiz */}
        <div id="intro-container">
          <h4 className="section-heading">
            Now you have learnt about different kinds of marine threats!
          </h4>
          <h4 className="section-heading">
            But we havn't talked about something that exists in our everyday
            life - Pollution!
          </h4>
          <p className="section-text">Now, you can:</p>
          <div className="row option">
            <div className="col-4 sub-option">
              <p className="section-text">
                Take a quiz and test what you've learnt about ocean threats.
              </p>
              <button
                type="button"
                className="btn btn-outline-light"
                onClick={() => setIsQuizOpen(true)}
              >
                Start Quiz
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  fill="currentColor"
                  className="bi bi-arrow-right-circle-fill ms-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                </svg>
              </button>
              <QuizPopup
                isOpen={isQuizOpen}
                onClose={() => setIsQuizOpen(false)}
                quizData={quizData}
              />
            </div>
            <div className="col-1 mt-4">
              <h3>OR</h3>
            </div>
            <div className="col-4 sub-option">
              <p className="section-text">
                Continue your journey to learn about ocean pollution around you.
              </p>
              <button
                type="button"
                className="btn btn-outline-light"
                onClick={() => goToRoute("/ocean-pollution")}
              >
                Next
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  fill="currentColor"
                  className="bi bi-arrow-right-circle-fill ms-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default MarineThreats;
