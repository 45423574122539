import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation(); // Gets the current location object

  const isActive = (path) => {
    return location.pathname === path; // Checks if the current pathname is equal to the given path
  };

  return (
    <div className="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none">
      <Navbar
        bg="transparent"
        variant="dark"
        expand="xl"
        className="navbar-transparent"
      >
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Link to="/">
              <img
                src="logo/logo_light.png"
                alt="logo"
                height="40"
                className="bi"
                style={{ paddingLeft: "20px" }}
              />
            </Link>
            <Nav.Link
              as={Link}
              to="/"
              className={`btn btn-outline-light ${
                isActive("/") ? "active" : ""
              }`}
            >
              Home
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/endangered-species"
              className={`btn btn-outline-light ${
                isActive("/endangered-species") ? "active" : ""
              }`}
            >
              Ocean Animals
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/marine-threats"
              className={`btn btn-outline-light ${
                isActive("/marine-threats") ? "active" : ""
              }`}
            >
              Ocean Threats
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/ocean-pollution"
              className={`btn btn-outline-light ${
                isActive("/ocean-pollution") ? "active" : ""
              }`}
            >
              Ocean Pollution
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/ocean-conservation"
              className={`btn btn-outline-light ${
                isActive("/ocean-conservation") ? "active" : ""
              }`}
            >
              Ocean Helper
            </Nav.Link>
            <Dropdown>
              <Dropdown.Toggle className="btn btn-outline-light">
                Explore More
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  as={Link}
                  to="/australia"
                  className={`btn btn-outline-light ${
                    isActive("/australia") ? "active" : ""
                  }`}
                >
                  Ocean of Australia
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to="/media"
                  className={`btn btn-outline-light ${
                    isActive("/media") ? "active" : ""
                  }`}
                >
                  Ocean Books&Films
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
