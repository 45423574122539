import React, { useEffect, useState } from "react";
import Header from "./Header";
import "./Media.css";
import { useNavigate } from "react-router-dom";

const Media = () => {
  const [books, setBooks] = useState([]);
  const [movies, setMovies] = useState([]);
  const [activeCard, setActiveCard] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("Books");
  const navigate = useNavigate();

  // nav
  const goToRoute = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    // Fetch books data
    const fetchBooks = async () => {
      const response = await fetch("https://splash-db.onrender.com/api/book");
      const data = await response.json();
      setBooks(data);
    };

    // Fetch movies data
    const fetchMovies = async () => {
      const response = await fetch("https://splash-db.onrender.com/api/movie");
      const data = await response.json();
      setMovies(data);
    };

    fetchBooks();
    fetchMovies();
  }, []);

  useEffect(() => {
    // Function to update the active card's full information
    const updateActiveCard = () => {
      const activeCardElement = document.querySelector(
        ".stacked-cards li.active"
      );
      if (activeCardElement) {
        const title = activeCardElement.querySelector("img").alt;
        const description =
          activeCardElement.querySelector(".card-description").textContent;
        const link = activeCardElement.dataset.link;

        setActiveCard({
          title,
          description,
          link,
        });
      }
    };

    // Initialize stackedCards only when books or movies are fetched
    if (selectedCategory === "Books" && books.length > 0) {
      initializeStackedCards(updateActiveCard);
    } else if (selectedCategory === "Movies" && movies.length > 0) {
      initializeStackedCards(updateActiveCard);
    }
  }, [books, movies, selectedCategory]);

  const initializeStackedCards = (updateActiveCard) => {
    const script = document.createElement("script");
    script.src = "/stackedCards.js";
    script.async = true;
    script.onload = () => {
      const stackedCard = new window.stackedCards({
        selector: ".stacked-cards",
        layout: "slide",
        transformOrigin: "center",
        onClick: updateActiveCard, // Update active card's full information on click
      });
      stackedCard.init();
      updateActiveCard();
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setActiveCard({});
  };

  return (
    <main>
      <Header />
      <div id="marine-container">
        <div id="intro-container">
          <h4 className="section-heading">Ocean Themed Movies & Books</h4>
          <p className="section-heading">
            Discover some exciting ocean-themed books or movies to spark your
            curiosity and enhance your knowledge!
          </p>
          <p className="section-text">
            Click on the button to switch between movies and books collections
            to explore all of them!
          </p>
          <div className="btn-group" role="group" aria-label="Basic example">
            <button
              type="button"
              className={`btn ${
                selectedCategory === "Books"
                  ? "active btn-primary"
                  : "btn-outline-primary"
              }`}
              onClick={() => handleCategoryChange("Books")}
            >
              Books
            </button>
            <button
              type="button"
              className={`btn ${
                selectedCategory === "Movies"
                  ? "active btn-primary"
                  : "btn-outline-primary"
              }`}
              onClick={() => handleCategoryChange("Movies")}
            >
              Movies
            </button>
          </div>
        </div>

        <div className="container">
          <div className="row d-flex">
            {/* Cards section */}
            <div className="col-lg-8 col-md-12">
              <div className="mycards stacked-cards">
                <ul>
                  {selectedCategory === "Books" &&
                    books.map((book, index) => (
                      <li key={index} data-link={book.Link}>
                        <img src={`/book/${book.Title}.jpg`} alt={book.Title} />
                        <p className="card-description">{book.Description}</p>
                      </li>
                    ))}

                  {selectedCategory === "Movies" &&
                    movies.map((movie, index) => (
                      <li key={index} data-link={movie.Link}>
                        <img
                          src={`/movie/${movie.Title}.jpg`}
                          alt={movie.Title}
                        />
                        <p className="card-description">{movie.Description}</p>
                      </li>
                    ))}
                </ul>
              </div>
            </div>

            {/* Active card description section */}
            <div className="col-lg-4 col-md-12 d-flex flex-column justify-content-end mb-3">
              <div className="active-card-info">
                {activeCard.title ? (
                  <div className="card-details-container">
                    <h3 className="book-heading">{activeCard.title}</h3>
                    <p className="book-text">{activeCard.description}</p>
                    {activeCard.link && (
                      <a
                        href={activeCard.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-outline-light book"
                      >
                        More Details
                        <span className="tooltip external">Link To External Site</span>
                      </a>
                    )}
                  </div>
                ) : (
                  <p>Select a card to see details</p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div id="intro-container">
          <p className="section-text">
            Have you explored oceans of Australia yet? If not, check it out!
          </p>
          <button
              type="button"
              className="btn btn-outline-light long"
              onClick={() => goToRoute("/australia")}
            >
              Ocean of Australia
            </button>
        </div>
      </div>
    </main>
  );
};

export default Media;
