import "./FlipBook.css";
import React, { useState, useEffect, useRef } from 'react';
import PageFlip from 'react-pageflip';

const Book = ({ onFlip }) => {

  return (
    <PageFlip 
      width={460}
      height={660}
      size="stretch" 
      maxShadowOpacity={0.5}
      onFlip={onFlip}
    >
    <div className="page" style={{backgroundColor: 'transparent !important'}}>
      <img src="/story/intro.png"></img>
    </div>
    <div className="page">
      <img src="/story/1.png"></img>
    </div>
    <div className="page">
    <img src="/story/2.png"></img>
    </div>
    <div className="page">
    <img src="/story/3.png"></img>
    </div>
    <div className="page">
    <img src="/story/4.png"></img>
    </div>
    <div className="page">
      <img src="/story/5.png"></img>
    </div>
    <div className="page">
      <img src="/story/6.png"></img>
    </div>
    <div className="page">
      <img src="/story/7.png"></img>
    </div>
    <div className="page">
      <img src="/story/8.png"></img>
    </div>
  </PageFlip>
  );
};

export default Book;
