import React, { useMemo, useState } from "react";
import "./PopulationTimeline.css";

const PopulationTimeline = ({ speciesData }) => {
  const extendPopulationData = (species) => {
    const { endangered_year, ...yearlyData } = species;
    let extendedData = [];

    // Include only the years 2015 to 2024
    for (let year = 2015; year <= 2024; year++) {
      if (yearlyData[year]) {
        extendedData.push({ year, population: yearlyData[year] });
      }
    }

    // Directly add the endangered year if it's beyond 2024
    if (endangered_year > 2024) {
      extendedData.push({ year: endangered_year, population: 0 });
    }

    return extendedData;
  };

  const selectedSpeciesData = speciesData[0];
  const extendedPopulationData = useMemo(
    () => extendPopulationData(selectedSpeciesData),
    [selectedSpeciesData]
  );

  const [currentYear, setCurrentYear] = useState(2024);

  const handleYearChange = (e) => {
    const sliderValue = parseInt(e.target.value);
    // Calculate the index in the array to handle jumps
    const index =
      sliderValue >= extendedPopulationData.length
        ? extendedPopulationData.length - 1
        : sliderValue;
    setCurrentYear(extendedPopulationData[index].year);
  };

  const getIconCount = (population) => {
    if (population >= 10000) return Math.round(population / 1000);
    if (population >= 1000) return Math.round(population / 100);
    if (population >= 100) return Math.round(population / 10);
    return population;
  };

  const currentPopulation =
    extendedPopulationData.find((d) => d.year === currentYear)?.population || 0;
  console.log("current population", currentPopulation);

  return (
    <div className="timeline-container">
      <h5>When will {selectedSpeciesData.name} disappear?</h5>
      <div className="slider-container">
        <input
          type="range"
          min={0} // Start at the first index
          max={extendedPopulationData.length - 1} // End at the last index
          value={extendedPopulationData.findIndex(
            (d) => d.year === currentYear
          )}
          onChange={handleYearChange}
          className="slider"
        />
        <div className="slider-labels">
          {extendedPopulationData.map(({ year }) => (
            <span
              key={year}
              className={`slider-label ${currentYear === year ? "active" : ""}`}
            >
              {year}
            </span>
          ))}
        </div>
      </div>

      <div className="population-info">
        <p>
          Year: <strong>{currentYear}</strong>
        </p>
        <p>
          Number: <strong>{currentPopulation}</strong>
        </p>
        <div className="population-icons">
          {Array.from({ length: getIconCount(currentPopulation) }).map(
            (_, i) => (
              <img
                key={i}
                src={`/fishicon/${selectedSpeciesData.name}.png`}
                alt="population icon"
                className="population-icon"
              />
            )
          )}
        </div>

        {/* Display extinction message if the current year is the endangered year */}
        {currentYear === selectedSpeciesData.endangered_year && (
          <>
            <h5 className="extinction-warning">
              {selectedSpeciesData.name} is in danger of disappearing by {" "}
              {selectedSpeciesData.endangered_year} unless we protect its home!
            </h5>
          </>
        )}
      </div>
    </div>
  );
};

export default PopulationTimeline;
