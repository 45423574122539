import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import "./Home.css";

const Home = () => {
  const navigate = useNavigate();

  const goToRoute = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <main>
      <div className="video-header-container">
        <Header />
        <div className="video-container">
        <button className="btn btn-outline-light special" onClick={() => goToRoute("/endangered-species")}>
          Start Your Adventure
        </button>
          <video autoPlay loop muted className="video-background">
            <source src="/Covers/home.mp4" type="video/mp4" />
            Your browser does not support the video.
          </video>
        </div>
      </div>
{/* 
      <div id="intro-container">
        <button className="btn btn-outline-light special" onClick={() => goToRoute("/endangered-species")}>
          Start Your Adventure
        </button>
      </div> */}

      <div className="container px-4 pb-5" id="custom-cards">
        <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4">
          <div className="col">
            <div
              className="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg"
              style={{
                position: "relative",
                backgroundImage: "url('/homeicon/1.png')",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  zIndex: 1,
                }}
              ></div>
              <div
                className="d-flex flex-column justify-content-center align-items-center h-100 p-5 text-white text-shadow-1"
                style={{ position: "relative", zIndex: 2 }}
              >
                <h3 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">
                  Some famous endangered marine species in Australia!
                </h3>
                <div className="position-absolute bottom-0 end-0 p-3"></div>
                <button
                  onClick={() => goToRoute("/endangered-species")}
                  className="go-button"
                >
                  <img src="/go.png" alt="Go" />
                </button>
              </div>
            </div>
          </div>

          <div className="col">
            <div
              className="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg"
              style={{
                position: "relative",
                backgroundImage: "url('/homeicon/3.png')",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  zIndex: 1,
                }}
              ></div>
              <div
                className="d-flex flex-column justify-content-center align-items-center h-100 p-5 text-white text-shadow-1"
                style={{ position: "relative", zIndex: 2 }}
              >
                <h3 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">
                  Things that harm the ocean and its creatures.
                </h3>
                <div className="position-absolute bottom-0 end-0 p-3"></div>
                <button
                  onClick={() => goToRoute("/marine-threats")}
                  className="go-button"
                >
                  <img src="/go.png" alt="Go" />
                </button>
              </div>
            </div>
          </div>


          <div className="col">
            <div
              className="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg"
              style={{
                backgroundImage: "url('/homeicon/2.png')",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.2)", // lighter semi-transparent black
                  zIndex: 1,
                }}
              ></div>
              <div
                className="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1"
                style={{ position: "relative", zIndex: 2 }}
              >
                <h3 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">
                  Different kinds of ocean pollution and how to recognise them.
                </h3>
                <div className="position-absolute bottom-0 end-0 p-3"></div>
                <button
                  onClick={() => goToRoute("/ocean-pollution")}
                  className="go-button"
                >
                  <img src="/go.png" alt="Go" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="break"></div>

        <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4">
          <div className="col">
            <div
              className="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg"
              style={{
                position: "relative",
                backgroundImage: "url('/homeicon/4.png')",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.2)", 
                  zIndex: 1,
                }}
              ></div>
              <div
                className="d-flex flex-column justify-content-center align-items-center h-100 p-5 text-white text-shadow-1"
                style={{ position: "relative", zIndex: 2 }}
              >
                <h3 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">
                  What can we do to protect our ocean and its animals? 
                </h3>
                <div className="position-absolute bottom-0 end-0 p-3"></div>
                <button
                  onClick={() => goToRoute("/ocean-conservation")}
                  className="go-button"
                >
                  <img src="/go.png" alt="Go" />
                </button>
              </div>
            </div>
          </div>

          <div className="col">
            <div
              className="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg"
              style={{
                position: "relative",
                backgroundImage: "url('/homeicon/5.png')",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  zIndex: 1,
                }}
              ></div>
              <div
                className="d-flex flex-column justify-content-center align-items-center h-100 p-5 text-white text-shadow-1"
                style={{ position: "relative", zIndex: 2 }}
              >
                <h3 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">
                  Explore the ocean environment across Australia.
                </h3>
                <div className="position-absolute bottom-0 end-0 p-3"></div>
                <button
                  onClick={() => goToRoute("/australia")}
                  className="go-button"
                >
                  <img src="/go.png" alt="Go" />
                </button>
              </div>
            </div>
          </div>


          <div className="col">
            <div
              className="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg"
              style={{
                backgroundImage: "url('/homeicon/6.png')",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  zIndex: 1,
                }}
              ></div>
              <div
                className="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1"
                style={{ position: "relative", zIndex: 2 }}
              >
                <h3 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">
                  Movies and books recommendations to explore more.
                </h3>
                <div className="position-absolute bottom-0 end-0 p-3"></div>
                <button
                  onClick={() => goToRoute("/media")}
                  className="go-button"
                >
                  <img src="/go.png" alt="Go" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Home;

// im so tireddddd
