import React, { useState } from "react";
import validator from 'validator';

const Password = ({ correctPassword, children }) => {
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  // Update handlePasswordSubmit function
  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    const sanitizedPassword = validator.escape(password);
    if (sanitizedPassword === correctPassword) {
      setIsAuthenticated(true);
    } else {
      alert("Incorrect password");
    }
  };

  if (isAuthenticated) {
    return <>{children}</>;
  }

  return (
    <div
  style={{
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.85)",
    display: "flex",
    flexDirection: "column", 
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2000,
    color: "#fff",
  }}
>
  <img
    src="logo/team_logo.png"
    alt="Logo"
    style={{
      width: "350px",
      marginBottom: "40px",
    }}
  />
  <h1 style={{ marginBottom: "40px", textAlign: "center", fontWeight:"bold"}}>
    Welcome!
  </h1>
  <h4 style={{ marginBottom: "20px", textAlign: "center",}}>
    This is a password protected site.
  </h4>
  <h4 style={{ marginBottom: "20px", textAlign: "center",}}>
    Please enter the secret key to continue.
  </h4>
  <form
    onSubmit={handlePasswordSubmit}
    style={{
      backgroundColor: "#333",
      padding: "20px 30px",
      borderRadius: "10px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }}
  >
    <label
      style={{
        marginBottom: "15px",
        fontSize: "18px",
        fontWeight: "bold",
      }}
    >
      Your secret key: 
    </label>
    <input
      type="password"
      value={password}
      onChange={handlePasswordChange}
      style={{
        padding: "10px",
        fontSize: "16px",
        borderRadius: "5px",
        border: "none",
        marginBottom: "20px",
        width: "100%",
        maxWidth: "300px",
      }}
    />
    <button
      type="submit"
      style={{
        padding: "10px 20px",
        fontSize: "16px",
        backgroundColor: "#4CAF50",
        color: "#fff",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        transition: "background-color 0.3s ease",
      }}
    >
      GO
    </button>
  </form>
</div>


  );
};

export default Password;
